<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        style="display: flex; flex-direction: column"
        [magic]="mgc.product"
    >
        <div class="divblock">
            <div class="example-container-noborder">
                <mat-table
                    #table
                    [dataSource]="dataSource"
                    class="container_border"
                    matSort
                    matSortDisableClear
                    (matSortChange)="tableService.sortData($event)"
                >
                    <ng-container
                        [magic]="mgc.Column6"
                        [matColumnDef]="mgc.Column6"
                    >
                        <mat-header-cell *matHeaderCellDef>
                            p_BID
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <div>
                                        <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                            <div>
                                                <input
                                                    *ngIf="mg.isRowInRowEditing(row)"
                                                    matInput
                                                    type='number'
                                                    [magic]="mgc.P_p_BID"
                                                    [rowId]="row.rowId"
                                                    [formControlName]="mgc.P_p_BID"
                                                    mgFormat
                                                >
                                                <mgError
                                                    [magic]=mgc.P_p_BID
                                                    [rowId]="row.rowId"
                                                > </mgError>
                                            </div>
                                        </mat-form-field>
                                        <label
                                            *ngIf="!mg.isRowInRowEditing(row)"
                                            [magic]="mgc.P_p_BID"
                                            [rowId]="row.rowId"
                                        >
                                            {{mg.getValue(mgc.P_p_BID, row.rowId)
}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column7"
                        [matColumnDef]="mgc.Column7"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                        >
                            BID
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <div>
                                        <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                            <div>
                                                <input
                                                    *ngIf="mg.isRowInRowEditing(row)"
                                                    matInput
                                                    type='number'
                                                    [magic]="mgc.companymain_products_BID"
                                                    [rowId]="row.rowId"
                                                    [formControlName]="mgc.companymain_products_BID"
                                                    mgFormat
                                                    rangevalidator
                                                >
                                                <mgError
                                                    [magic]=mgc.companymain_products_BID
                                                    [rowId]="row.rowId"
                                                > </mgError>
                                            </div>
                                        </mat-form-field>
                                        <label
                                            *ngIf="!mg.isRowInRowEditing(row)"
                                            [magic]="mgc.companymain_products_BID"
                                            [rowId]="row.rowId"
                                        >
                                            {{mg.getValue(mgc.companymain_products_BID, row.rowId)
}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column8"
                        [matColumnDef]="mgc.Column8"
                    >
                        <mat-header-cell *matHeaderCellDef>
                            prod_name
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <div>
                                        <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                            <div>
                                                <input
                                                    *ngIf="mg.isRowInRowEditing(row)"
                                                    matInput
                                                    [magic]="mgc.companymain_products_prod_name"
                                                    [rowId]="row.rowId"
                                                    [formControlName]="mgc.companymain_products_prod_name"
                                                    mgFormat
                                                >
                                                <mgError
                                                    [magic]=mgc.companymain_products_prod_name
                                                    [rowId]="row.rowId"
                                                > </mgError>
                                            </div>
                                        </mat-form-field>
                                        <label
                                            *ngIf="!mg.isRowInRowEditing(row)"
                                            [magic]="mgc.companymain_products_prod_name"
                                            [rowId]="row.rowId"
                                        >
                                            {{mg.getValue(mgc.companymain_products_prod_name, row.rowId)
}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column9"
                        [matColumnDef]="mgc.Column9"
                    >
                        <mat-header-cell *matHeaderCellDef>
                            prod_desc
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <div>
                                        <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                            <div>
                                                <input
                                                    *ngIf="mg.isRowInRowEditing(row)"
                                                    matInput
                                                    [magic]="mgc.companymain_products_prod_desc"
                                                    [rowId]="row.rowId"
                                                    [formControlName]="mgc.companymain_products_prod_desc"
                                                    mgFormat
                                                >
                                                <mgError
                                                    [magic]=mgc.companymain_products_prod_desc
                                                    [rowId]="row.rowId"
                                                > </mgError>
                                            </div>
                                        </mat-form-field>
                                        <label
                                            *ngIf="!mg.isRowInRowEditing(row)"
                                            [magic]="mgc.companymain_products_prod_desc"
                                            [rowId]="row.rowId"
                                        >
                                            {{mg.getValue(mgc.companymain_products_prod_desc, row.rowId)
}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: displayedColumns;"
                        class="table_row"
                        [ngClass]="{ 'selected': selection.isSelected(row)}"
                        [magicRow]="row?.rowId"
                        (click)="row? tableService.selectRow(row.rowId) : null"
                    >
                    </mat-row>
                </mat-table>
                <mat-paginator
                    #paginator
                    [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 20]"
                    (page)="tableService.mgOnPaginateChange($event)"
                >
                </mat-paginator>
            </div>
        </div>
    </div>
</div>
