import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    province = "province",
        Table1 = "Table1",
        Column4 = "Column4",
        province_province = "province_province",
        Column5 = "Column5",
        province_seq = "province_seq",
}
export enum MgCustomProperties {}
export var
    MgDisplayedColumns = [
        'Column4',
        'Column5',
    ];

export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get province_province(): FormControl {
        return this.getTableChildFormControl(MgControlName.province_province);
    }

    get province_seq(): FormControl {
        return this.getTableChildFormControl(MgControlName.province_seq);
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}