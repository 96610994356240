import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    login = "login",
        Label2 = "Label2",
        V_email = "V_email",
        Edit4 = "Edit4",
        Check_box5 = "Check_box5",
        Combo_box6 = "Combo_box6",
        List_box8 = "List_box8",
        Radio_button9 = "Radio_button9",
        Button3 = "Button3",
}
export enum MgCustomProperties {}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get V_email(): FormControl {
        return this.fg.controls[MgControlName.V_email] as FormControl;
    }

    get Edit4(): FormControl {
        return this.fg.controls[MgControlName.Edit4] as FormControl;
    }

    get Check_box5(): FormControl {
        return this.fg.controls[MgControlName.Check_box5] as FormControl;
    }

    get Combo_box6(): FormControl {
        return this.fg.controls[MgControlName.Combo_box6] as FormControl;
    }

    get List_box8(): FormControl {
        return this.fg.controls[MgControlName.List_box8] as FormControl;
    }

    get Radio_button9(): FormControl {
        return this.fg.controls[MgControlName.Radio_button9] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}
