
<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        style="display: flex; flex-direction: column"
        [magic]="mgc.login"
    >
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label2"
                class="lable_overflow"
            >
            <mat-icon>copyright</mat-icon> {{mg.getText(mgc.Label2)}}
            </label>
        </div>
        <div class="divflex">
            <div>
                <mat-form-field>
                    <div>
                        <input
                            matInput
                            [magic]="mgc.V_email"
                            placeholder="Email"
                            [formControlName]="mgc.V_email"
                            mgFormat
                        >
                        <mgError [magic]=mgc.V_email> </mgError>
                    </div>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <div>
                        <input
                            matInput
                            [magic]="mgc.Edit4"
                            placeholder="Password"
                            [formControlName]="mgc.Edit4"
                            mgFormat
                        >
                        <mgError [magic]=mgc.Edit4> </mgError>
                    </div>
                </mat-form-field>
            </div>
        </div>
        <div style="display: flex; flex-direction: row">
            <div>
                <mat-slide-toggle labelPosition="before"
                    [magic]="mgc.Check_box5"
                    [formControlName]="mgc.Check_box5"
                >
                    Admin
                </mat-slide-toggle>
            </div>
        </div>
        <div class="divflex">
            <div>
                <mat-form-field>
                    <mat-select
                        [magic]="mgc.Combo_box6"
                        [formControlName]="mgc.Combo_box6"
                    >
                        <mat-option
                            *ngFor="let o of mg.getItemListValues(mgc.Combo_box6);"
                            [value]="o.index"
                        >
                            {{o.displayValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div style="display: flex; flex-direction: row">
            <div>
                <mat-selection-list
                    multiple
                    [formControlName]="mgc.List_box8"
                    [magic]="mgc.List_box8"
                    [tabIndex]="screenFormGroup.get(mgc.List_box8).disabled ? -1 : 0"
                >
                    <mat-list-option checkboxPosition="before"
                        *ngFor="let o of mg.getItemListValues(mgc.List_box8);"
                        [value]="o.index"
                    >
                        {{o.displayValue}}
                    </mat-list-option>
                </mat-selection-list>
            </div>
        </div>
        <div style="display: flex; flex-direction: row">
            <mat-radio-group
                (change)="task.mgOnRadioSelectionChanged($event,mgc.Radio_button9)"
                [magic]="mgc.Radio_button9"
                [magic]="mgc.Radio_button9"
                [formControlName]="mgc.Radio_button9"
            >
                <mat-radio-button
                    *ngFor="let o of mg.getItemListValues(mgc.Radio_button9);"
                    [value]="o.index"
                >
                    {{o.displayValue}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div style="display: flex; flex-direction: row">
            <button
                mat-raised-button
                color="primary"
                [magic]="mgc.Button3"
            >
                Exit
            </button>
        </div>
    </div>
</div>
