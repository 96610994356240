<header style="background-color: rgba(255, 255, 255, 0.6);" class="fixed-top" data-pg-collapsed>
  <nav class="border-bottom navbar navbar-expand-lg navbar-light pb-lg-0 pt-lg-0">
      <div class="container"> <a class="fw-bold navbar-brand" href=".">Directory.co.th</a>
          <ul class="align-items-lg-center flex-row ms-auto me-2 me-lg-0 navbar-nav order-lg-2">
              <li class="d-none d-sm-block nav-item">
</li>
              <li class="ms-2 nav-item"> <a class="btn btn-dark  p-2 rounded-pill" href="#" aria-label="Search button"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="1.25em" height="1.25em" class="d-block">
                          <g>
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z"></path>
                          </g>
                      </svg></a>
              </li>
          </ul>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown-3" aria-controls="navbarNavDropdown-3" aria-expanded="false" aria-label="Toggle navigation"> <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse " id="navbarNavDropdown-3">
              <ul class="navbar-nav ">
                  <li class="nav-item"> <a class="nav-link px-lg-3 py-lg-4 text-dark" routerLink="register">Explorer</a>
                  </li>
                  <li class="nav-item dropdown">
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                          <li><a class="dropdown-item" href="#">Listing 1: Grid Listing</a>
                          </li>
                          <li><a class="dropdown-item" href="#">Listing 2: Vertical Listing</a>
                          </li>
                          <li><a class="dropdown-item" href="#">Listing 3: Full width + Map on the side</a>
                          </li>
                          <li>
                              <hr class="dropdown-divider">
                          </li>
                          <li><a class="dropdown-item" href="#">Details 1: Reponsive Style 1</a>
                          </li>
                          <li><a class="dropdown-item" href="#">Details 2: Reponsive Style 2</a>
                          </li>
                          <li>
                              <hr class="dropdown-divider">
                          </li>
                          <li><a class="dropdown-item" href="#">Sample Page</a>
                          </li>
                      </ul>
                  </li>
                  <li class="nav-item"> <a class="nav-link px-lg-3 py-lg-4 text-dark" href="#">Blog</a>
                  </li>
                  <li class="nav-item">
                  </li>
                  <li class="nav-item">
                  </li>
              </ul>
              <ul class="align-items-lg-center ms-auto navbar-nav">
                  <li class="nav-item"> <a class="nav-link px-lg-3 py-lg-4 text-dark"  routerLink="register"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="1.25em" height="1.25em" class="me-2">
                              <g>
                                  <path fill="none" d="M0 0h24v24H0z"></path>
                                  <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-4.987-3.744A7.966 7.966 0 0 0 12 20c1.97 0 3.773-.712 5.167-1.892A6.979 6.979 0 0 0 12.16 16a6.981 6.981 0 0 0-5.147 2.256zM5.616 16.82A8.975 8.975 0 0 1 12.16 14a8.972 8.972 0 0 1 6.362 2.634 8 8 0 1 0-12.906.187zM12 13a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"></path>
                              </g>
                          </svg>Login/Sign Up</a>
                  </li>
              </ul>
          </div>
      </div>
  </nav>
</header>

        <main>
            <section class="pb-5 pt-5">
                <div class="container pb-2 pt-2">
                    <div class="gx-lg-5 gy-4 row" data-pg-collapsed>
                        <div class="col-lg-5 col-xl-4 order-lg-1" data-pg-collapsed>
                            <div class="bg-light p-4 position-sticky top-0">
                                <h2 class="h5 text-dark">Filter Search</h2>
                                <hr/>



                            </div>
                        </div>
                        <div class="col-lg-7 col-xl-8 order-lg-0">
                            <div class="align-items-center g-2 mb-3 row" data-pg-collapsed>
                                <div class="col">
                                    <h1 class="h5 mb-0 text-dark">Result for: <span class="fw-normal small text-secondary">search text</span></h1>
                                </div>
                                <div class="col-auto">
                                    <button type="button" class="align-items-center btn btn-outline-primary d-inline-flex p-2" aria-label="List View">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="1em" height="1em">
                                            <path fill="none" d="M0 0h24v24H0z"/>
                                            <path d="M8 4h13v2H8V4zm-5-.5h3v3H3v-3zm0 7h3v3H3v-3zm0 7h3v3H3v-3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"/>
                                        </svg>
                                    </button>
                                    <button type="button" class="align-items-center btn btn-outline-primary d-inline-flex p-2" aria-label="Grid View">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="1em" height="1em">
                                            <path fill="none" d="M0 0h24v24H0z"/>
                                            <path d="M22 12.999V20a1 1 0 0 1-1 1h-8v-8.001h9zm-11 0V21H3a1 1 0 0 1-1-1v-7.001h9zM11 3v7.999H2V4a1 1 0 0 1 1-1h8zm10 0a1 1 0 0 1 1 1v6.999h-9V3h8z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div>
                                <div class="border mb-4" data-pg-collapsed>
                                    <div class="align-items-center gx-0 row">
                                        <div class="col-lg-12 col-md-5 col-xl-4">

                                            <div style="padding: 1%;"
                                            novalidate
                                            [formGroup]="screenFormGroup"
                                            >
                                            <div
                                            class="divflex"
                                            [magic]="mgc.startprogram"
                                            >
                                            <div class="divflex">
                                            <router-outlet [magic]="mgc.MainOutlet">
                                            </router-outlet>
                                            </div>
                                            </div>
                                            </div>

                                        </div>
                                        <div class="col-md">
                                            <div class="pb-3 pe-4 ps-4 pt-3">
                                                <div class="align-items-center d-flex justify-content-between">
                                                    <div class="pb-1 pt-1">
                                                        <a href="#" class="link-dark text-decoration-none"><h2 class="h5 mb-1">Company Name</h2><h2 class="h5 mb-1"></h2></a>

                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="1.125em" height="1.125em" class="me-1 text-primary">
                                                                <g>
                                                                    <path fill="none" d="M0 0h24v24H0z"/>
                                                                    <path d="M18.364 17.364L12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
                                                                </g>
                                                            </svg>
                                                            <span class="align-middle">Address Thailand</span>
                                                        </div>
                                                    </div>
                                                    <a class="btn btn-primary ms-2 p-2 rounded-pill" href="#" aria-label="add to favorite"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="1em" height="1em" class="d-block">
                                                            <g>
                                                                <path fill="none" d="M0 0H24V24H0z"/>
                                                                <path d="M20.243 4.757c2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236C5.515 3 8.093 2.56 10.261 3.44L6.343 7.358l1.414 1.415L12 4.53l-.013-.014.014.013c2.349-2.109 5.979-2.039 8.242.228z"/>
                                                            </g>
                                                        </svg></a>
                                                </div>
                                                <hr/>
                                                <div class="align-items-center d-flex justify-content-between">
                                                    <div class="pb-1 pt-1">
                                                        <span class="me-1 text-primary"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="1.125em" height="1.125em">
                                                                <g>
                                                                    <path fill="none" d="M0 0h24v24H0z"/>
                                                                    <path d="M12 18.26l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928z"/>
                                                                </g>
                                                            </svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="1.125em" height="1.125em">
                                                                <g>
                                                                    <path fill="none" d="M0 0h24v24H0z"/>
                                                                    <path d="M12 18.26l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928z"/>
                                                                </g>
                                                            </svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="1.125em" height="1.125em">
                                                                <g>
                                                                    <path fill="none" d="M0 0h24v24H0z"/>
                                                                    <path d="M12 18.26l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928z"/>
                                                                </g>
                                                            </svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="1.125em" height="1.125em">
                                                                <g>
                                                                    <path fill="none" d="M0 0h24v24H0z"/>
                                                                    <path d="M12 18.26l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928z"/>
                                                                </g>
                                                            </svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="1.125em" height="1.125em">
                                                                <g>
                                                                    <path fill="none" d="M0 0h24v24H0z"/>
                                                                    <path d="M12 15.968l4.247 2.377-.949-4.773 3.573-3.305-4.833-.573L12 5.275v10.693zm0 2.292l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928L12 18.26z"/>
                                                                </g>
                                                            </svg></span>
                                                        <span class="align-middle">432 reviews</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pb-3 pt-3 text-center" data-pg-collapsed>
                                <a class="align-items-center btn btn-primary d-inline-flex pe-3 ps-3 rounded-pill" href="#"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="1.25em" height="1.25em" class="me-2">
                                        <path fill="none" d="M0 0h24v24H0z"/>
                                        <path d="M5.463 4.433A9.961 9.961 0 0 1 12 2c5.523 0 10 4.477 10 10 0 2.136-.67 4.116-1.81 5.74L17 12h3A8 8 0 0 0 6.46 6.228l-.997-1.795zm13.074 15.134A9.961 9.961 0 0 1 12 22C6.477 22 2 17.523 2 12c0-2.136.67-4.116 1.81-5.74L7 12H4a8 8 0 0 0 13.54 5.772l.997 1.795z"/>
                                    </svg><span>Load More</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="bg-primary py-4 text-white" data-pg-collapsed>
                <div class="container py-3">
                    <div class="align-items-center row">
                        <div class="col-lg-7">
                            <h2 class="mb-3">Join Our Amazing Community</h2>
                            <p class="fw-light">                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        </div>
                        <div class="col-lg-auto ms-auto"> <a class="btn btn-outline-light ps-4 pe-4 rounded-pill" href="#">Register Now</a>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <footer class="bg-dark pt-5 text-secondary" data-pg-collapsed>
            <div class="container">
                <div class="row">
                    <div class="col-xl-4 py-3"> <a href="#" class="d-inline-block fw-bold h2 mb-4 text-decoration-none text-white">Directory.co.th</a>
                        <p class="mb-3">                                Duis pharetra venenatis felis, ut tincidunt ipsum consequat nec. Fusce et porttitor libero, eu aliquam nisi. Nam finibus ullamcorper semper.</p>
                        <div class="mb-4"> <a href="#" class="link-secondary text-decoration-none">+66 86 700-0087</a> <br> <a href="#" class="link-secondary text-decoration-none">hello@directory.co.th</a>
                        </div>
                    </div>
                    <div class="col-lg-7 col-xl-4 py-3">
                        <h2 class="h5 mb-4 text-white">Popular Interests</h2>
                        <div class="row">
                            <div class="col-sm-6">
                                <ul class="list-unstyled">
                                    <li class="mb-3"> <a href="#" class="link-secondary text-decoration-none">Fitness</a>
                                    </li>
                                    <li class="mb-3"> <a href="#" class="link-secondary text-decoration-none">Events</a>
                                    </li>
                                    <li class="mb-3"> <a href="#" class="link-secondary text-decoration-none">Trekking</a>
                                    </li>
                                    <li class="mb-3"> <a href="#" class="link-secondary text-decoration-none">Auto Parts</a>
                                    </li>
                                    <li class="mb-3"> <a href="#" class="link-secondary text-decoration-none">Co-working Spaces</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-sm-6">
                                <ul class="list-unstyled">
                                    <li class="mb-3"> <a href="#" class="link-secondary text-decoration-none">Banks</a>
                                    </li>
                                    <li class="mb-3"> <a href="#" class="link-secondary text-decoration-none">ATMs</a>
                                    </li>
                                    <li class="mb-3"> <a href="#" class="link-secondary text-decoration-none">Services</a>
                                    </li>
                                    <li class="mb-3"> <a href="#" class="link-secondary text-decoration-none">Shopping Centers</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-xl-4 py-3">
                        <h2 class="h5 mb-4 text-white">Subscribe</h2>
                        <p class="mb-3">Subscribe to our newsletter and get exclusive updates directly in your inbox.</p>




                        <h2 class="h5 mb-3 text-white">Get Social</h2>
                        <div class="d-inline-flex flex-wrap"> <a href="#" class="link-secondary p-1" aria-label="facebook link"> <svg viewBox="0 0 24 24" fill="currentColor" width="20" height="20">
                                    <path d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z"/>
                                </svg> </a> <a href="#" class="link-secondary p-1" aria-label="twitter link"> <svg viewBox="0 0 24 24" fill="currentColor" width="20" height="20">
                                    <path d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z"/>
                                </svg> </a> <a href="#" class="link-secondary p-1" aria-label="instagram link"> <svg viewBox="0 0 24 24" fill="currentColor" width="20" height="20">
                                    <path d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z"/>
                                </svg> </a> <a href="#" class="link-secondary p-1" aria-label="linkedin link"> <svg viewBox="0 0 24 24" fill="currentColor" width="20" height="20">
                                    <path d="M6.94 5a2 2 0 1 1-4-.002 2 2 0 0 1 4 .002zM7 8.48H3V21h4V8.48zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68z"/>
                                </svg> </a> <a href="#" class="link-secondary p-1" aria-label="youtube link"> <svg viewBox="0 0 24 24" fill="currentColor" width="20" height="20">
                                    <path d="M21.543 6.498C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5l6-3.5-6-3.5v7z"/>
                                </svg> </a>
                        </div>
                    </div>
                </div>
                <div class="pb-3 pt-3 small">
                    <hr class="border-secondary mt-0">
                    <div class="align-items-center row">
                        <div class="col-md pb-2 pt-2">
                            <p class="mb-0">&copy; 2024 | All Rights Reserved - Directory.co.th</p>
                        </div>
                        <div class="col-md-auto pb-2 pt-2"><a href="#" class="link-secondary text-decoration-none">Privacy Policy</a> | <a href="#" class="link-secondary text-decoration-none">Terms of Use</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

