import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    company = "company",
        Table1 = "Table1",
        Column4 = "Column4",
        companymain_BID = "companymain_BID",
        Column5 = "Column5",
        companymain_business_name = "companymain_business_name",
        Column6 = "Column6",
        Button7 = "Button7",
        companyoutlet = "companyoutlet",
}
export enum MgCustomProperties {}
export var
    MgDisplayedColumns = [
        'Column4',
        'Column5',
        'Column6',
    ];

export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get companymain_BID(): FormControl {
        return this.getTableChildFormControl(MgControlName.companymain_BID);
    }

    get companymain_business_name(): FormControl {
        return this.getTableChildFormControl(MgControlName.companymain_business_name);
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}