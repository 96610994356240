import { Routes, RouterModule } from '@angular/router';
import { RouterContainerMagicComponent } from "@magic-xpa/angular";
import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
export const routes: Routes = [

    {
        path: 'login/:email/:password',
        component: RouterContainerMagicComponent,

    },
    {
        path: 'mainmenu',
        component: RouterContainerMagicComponent,

    },
    {
        path: 'province',
        component: RouterContainerMagicComponent,

    },
    {
        path: 'company',
        component: RouterContainerMagicComponent,


        children: [{
            path: 'product',
            component: RouterContainerMagicComponent,
            outlet: 'companyoutlet',

        }, ]
    },

];

@NgModule({
    imports: [CommonModule,
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule]
})
export class MagicRoutingModule {}