import { startprogram as startprogram_startprogram } from './startprogram/startprogram.component';
import { login as login_login } from './login/login.component';
import { mainmenu as mainmenu_mainmenu } from './mainmenu/mainmenu.component';
import { province as province_province } from './province/province.component';
import { company as company_company } from './company/company.component';
import { product as company_product_product } from './company/product/product.component';
import { register as register_register } from './register/register.component';

export const title = "";

export const magicGenCmpsHash = {               startprogram_startprogram:startprogram_startprogram,
              login_login:login_login,
              mainmenu_mainmenu:mainmenu_mainmenu,
              province_province:province_province,
              company_company:company_company,
              company_product_product:company_product_product,
              register_register:register_register,
       
};

export const magicGenComponents = [ startprogram_startprogram,
login_login,
mainmenu_mainmenu,
province_province,
company_company,
company_product_product,
register_register 
];


export const LazyLoadModulesMap = {};