import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    product = "product",
        Table1 = "Table1",
        Column6 = "Column6",
        P_p_BID = "P_p_BID",
        Column7 = "Column7",
        companymain_products_BID = "companymain_products_BID",
        Column8 = "Column8",
        companymain_products_prod_name = "companymain_products_prod_name",
        Column9 = "Column9",
        companymain_products_prod_desc = "companymain_products_prod_desc",
}
export enum MgCustomProperties {}
export var
    MgDisplayedColumns = [
        'Column6',
        'Column7',
        'Column8',
        'Column9',
    ];

export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get P_p_BID(): FormControl {
        return this.getTableChildFormControl(MgControlName.P_p_BID);
    }

    get companymain_products_BID(): FormControl {
        return this.getTableChildFormControl(MgControlName.companymain_products_BID);
    }

    get companymain_products_prod_name(): FormControl {
        return this.getTableChildFormControl(MgControlName.companymain_products_prod_name);
    }

    get companymain_products_prod_desc(): FormControl {
        return this.getTableChildFormControl(MgControlName.companymain_products_prod_desc);
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}