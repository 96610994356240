<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        style="display: flex; flex-direction: column"
        [magic]="mgc.mainmenu"
    >
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label1"
                class="lable_overflow"
            >
                Menu1
            </label>
        </div>
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label2"
                class="lable_overflow"
            >
                Menu2
            </label>
        </div>
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label3"
                class="lable_overflow"
            >
                Menu3
            </label>
        </div>
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label4"
                class="lable_overflow"
            >
                Menu4
            </label>
        </div>
    </div>
</div>