<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        style="display: flex; flex-direction: column"
        [magic]="mgc.province"
    >
        <div class="divflex">
            <div class="example-container mat-elevation-z8 Table1TableContainerProps">
                <mat-table
                    #table
                    [dataSource]="dataSource"
                    class="container_border"
                    matSort
                    matSortDisableClear
                    (matSortChange)="tableService.sortData($event)"
                >
                    <ng-container
                        [magic]="mgc.Column4"
                        [matColumnDef]="mgc.Column4"
                    >
                        <mat-header-cell *matHeaderCellDef>
                            province
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <div>
                                        <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                            <div>
                                                <input
                                                    *ngIf="mg.isRowInRowEditing(row)"
                                                    matInput
                                                    [magic]="mgc.province_province"
                                                    [rowId]="row.rowId"
                                                    [formControlName]="mgc.province_province"
                                                    mgFormat
                                                >
                                                <mgError
                                                    [magic]=mgc.province_province
                                                    [rowId]="row.rowId"
                                                > </mgError>
                                            </div>
                                        </mat-form-field>
                                        <label
                                            *ngIf="!mg.isRowInRowEditing(row)"
                                            [magic]="mgc.province_province"
                                            [rowId]="row.rowId"
                                        >
                                            {{mg.getValue(mgc.province_province, row.rowId)
}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column5"
                        [matColumnDef]="mgc.Column5"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                        >
                            seq
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <div>
                                        <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                            <div>
                                                <input
                                                    *ngIf="mg.isRowInRowEditing(row)"
                                                    matInput
                                                    type='number'
                                                    [magic]="mgc.province_seq"
                                                    [rowId]="row.rowId"
                                                    [formControlName]="mgc.province_seq"
                                                    mgFormat
                                                >
                                                <mgError
                                                    [magic]=mgc.province_seq
                                                    [rowId]="row.rowId"
                                                > </mgError>
                                            </div>
                                        </mat-form-field>
                                        <label
                                            *ngIf="!mg.isRowInRowEditing(row)"
                                            [magic]="mgc.province_seq"
                                            [rowId]="row.rowId"
                                        >
                                            {{mg.getValue(mgc.province_seq, row.rowId)
}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: displayedColumns;"
                        class="table_row"
                        [ngClass]="{ 'selected': selection.isSelected(row)}"
                        [magicRow]="row?.rowId"
                        (click)="row? tableService.selectRow(row.rowId) : null"
                    >
                    </mat-row>
                </mat-table>
                <mat-paginator
                    #paginator
                    [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 20]"
                    (page)="tableService.mgOnPaginateChange($event)"
                >
                </mat-paginator>
            </div>
        </div>
    </div>
</div>
