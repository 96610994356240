<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        style="display: flex; flex-direction: column"
        [magic]="mgc.register"
    >
        <div class="divflex">
            <div>
                <mat-form-field>
                    <div>
                        <input
                            matInput
                            [magic]="mgc.V_email"
                            placeholder="Email"
                            [formControlName]="mgc.V_email"
                            mgFormat
                        >
                        <mgError [magic]=mgc.V_email> </mgError>
                    </div>
                </mat-form-field>
            </div>
        </div>
        <div style="display: flex; flex-direction: row">
            <button
                mat-raised-button
                color="primary"
                [magic]="mgc.btregister"
            >
                Register
            </button>
            <button
                mat-raised-button
                color="primary"
                [magic]="mgc.Button4"
            >
                Cancel
            </button>
        </div>
    </div>
</div>
