import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { MagicModule ,OverlayContainerMagicProvider} from '@magic-xpa/angular';
import { MagicAngularMaterialModule } from '@magic-xpa/angular-material-core';
import { MagicGenLibModule } from './magic/magic.gen.lib.module';
import { MagicRoutingModule } from './app.routes';

import { from } from 'rxjs';
import { MyOverlayContainer } from './customoverlay/MyOverlayContainer';
import { MyOverlayContainerProvider } from './customoverlay/MyOverlayContainerProvider';

import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';

import { FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


@NgModule({
declarations: [
    AppComponent ,
    MyOverlayContainer
],
entryComponents:[
     MyOverlayContainer
],
imports:[
BrowserModule,
BrowserAnimationsModule,
ReactiveFormsModule,
MagicModule.forRoot(),
MagicAngularMaterialModule,
MagicGenLibModule,
MagicRoutingModule,
FormsModule,
MatFormFieldModule,
MatInputModule,
MatButtonModule,
MatDatepickerModule,
MatNativeDateModule,
MatTooltipModule,
MatIconModule,
MatSlideToggleModule,
],
exports: [MatButtonModule,MatIconModule,MatSlideToggleModule],
providers: [MatDatepickerModule,
{provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'fill', subscriptSizing:'dynamic', floatLabel: 'always'}},
{provide: OverlayContainerMagicProvider, useClass:MyOverlayContainerProvider}
],
bootstrap: [AppComponent]
})
export class AppModule {
}
